<template>
    <v-container v-if="listing.length > 0" class="newsAll2">
        <v-row>
            <v-col cols="12" lg="8" class="news-container">
                <template v-if="filteredListing.length > 0">
                    <template v-for="(list, key) in filteredListing" :key="key">
                        <template v-for="news in list" :key="news.id">
                            <router-link v-if="news?.texts" class="news-box" :to="'/news/' + news.slug" v-ripple>
                                <custom-image v-if="news?.imageTeaser" class="image" :image="news.imageTeaser[0]"/>
                                <section class="box-inside">
                                    <div class="meta-information">
                                       <span v-if="news?.author?.[0]">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                               fill="currentColor"
                                               class="bi bi-person"
                                               viewBox="0 0 16 16">
                                            <path
                                                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                          </svg>
                                         von {{ news?.author?.[0].firstname + ' ' + news?.author?.[0].lastname }}
                                       </span>
                                        <span v-if="news?.date">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                               fill="currentColor"
                                               class="bi bi-clock" viewBox="0 0 16 16">
                                            <path
                                                d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                                            <path
                                                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                                          </svg>
                                          {{ news.date }}
                                        </span>
                                        <div class="news-tags">
                                            <template v-if="news.categories">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     class="bi bi-tags" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3 2v4.586l7 7L14.586 9l-7-7zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586z"/>
                                                    <path
                                                        d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1z"/>
                                                </svg>
                                                {{ tagsString(news) }}
                                            </template>
                                        </div>
                                    </div>
                                    <template v-for="(textObject, key) in news.texts" :key="key">
                                        <h2 class="headline" v-html="textObject.headline"/>
                                        <p class="intro" v-html="textObject.intro"/>
                                        <p class="text" v-html="textObject.text"/>
                                        <custom-button :to="'/news/' + news.slug" class="read-all">
                                            Alles lesen
                                        </custom-button>
                                    </template>
                                </section>
                            </router-link>
                        </template>
                    </template>
                </template>
                <div v-else>
                    <h2>Keine passende News gefunden</h2>
                </div>
            </v-col>
            <v-col cols="12" lg="4" class="filter-container">
                <div class="news-filter">
                    <h2>Kategoriefilter</h2>
                    <div class="button-area">
                        <v-item-group v-model="selection" multiple>
                            <v-item
                                v-for="tag in tags"
                                v-slot="{ isSelected, toggle }"
                                :value="tag"
                                :key="tag"
                            >
                                <custom-button
                                    v-if="tag !== 'Alle'"
                                    :class="isSelected ? 'active' : ''"
                                    @click="toggle(); applyFilter();"
                                    elevation="0"
                                >
                                    {{ tag }}
                                </custom-button>
                                <custom-button
                                    v-else
                                    :class="selection.length === 0 ? 'active' : ''"
                                    @click="allButton"
                                    elevation="0"
                                >
                                    >
                                    {{ tag }}
                                </custom-button>
                            </v-item>
                        </v-item-group>
                    </div>
                </div>
                <div class="newsfeed-container">
                    <h2>Newsfeed abonnieren</h2>
                    <p>{{ themeStore.translate('news-all-v2.intro') }}</p>
                    <custom-button :general-link="{ link: newsfeed }" target="_blank">
                        Newsfeed abonnieren
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-rss" viewBox="0 0 16 16">
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                            <path d="M5.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m-3-8.5a1 1 0 0 1 1-1c5.523 0 10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8 1 1 0 0 1-1-1m0 4a1 1 0 0 1 1-1 6 6 0 0 1 6 6 1 1 0 1 1-2 0 4 4 0 0 0-4-4 1 1 0 0 1-1-1"/>
                        </svg>
                    </custom-button>
                    <p>{{ themeStore.translate('news-all-v2.footnote') }}</p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import {
    defineProps, onMounted,
    ref,
} from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import CustomButton from "@/components/reuseables/customButton.vue";
import {useThemeStore} from '@/store/theme';
import { getUniqueNewsCategories } from "@/mixins/global/globalMethods";

const themeStore = useThemeStore();
const newsfeed = themeStore.newsfeed;

const props = defineProps({
        listing: {
            type: Array,
        },
    }),
    tags = ref(getUniqueNewsCategories(props.listing)),
    selection = ref([]),
    filteredListing = ref([]);

function applyFilter() {
    if (!Array.isArray(props.listing)) return;
    filteredListing.value = props.listing.filter(({node: news}) => {
        if (selection.value.length === 0) return true;
        return news.categories?.some((category) => selection.value.includes(category.name));
    });
}

function allButton() {
    selection.value = [];
    applyFilter();
}

onMounted(() => {
    applyFilter();
});

function tagsString(news) {
    return news.categories?.map((category) => category.name).join(', ') ?? '';
}
</script>

<style lang="scss" scoped>
.news-box {
    margin-bottom: 4rem;
}

.news-container {
    @media (max-width: 79em) {
        order: 2;
    }
}

.filter-container {
    @media (max-width: 79em) {
        order: 1;
    }
}

.meta-information {
    display: flex;
    column-gap: 2rem;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;

    svg {
        margin-right: .5rem;
        transform: translateY(1px);
    }
}

h2 {
    margin-bottom: 1.5rem;
    font-weight: bold;
}

.newsfeed-container {
    padding: 2rem;
    margin-top: 2rem;
    background-color: var(--color-thingray);
    p {
        margin-bottom: 0;
    }
    .custom-button :deep(.v-btn__content){
        display: flex;
        gap: .5rem;
        svg {
            flex-shrink: 0;
            fill: white;
        }
    }
}

.custom-button.read-all {
    margin-top: 1rem;
    min-height: 3rem;
    min-width: 12rem;
}
</style>
