<template>
    <section class="membersExpansionPanel">
        <article class="single-director" v-for="(director, key) in data?.[0]?.content" :key="key">
          <h1 class="full-name">{{ director?.element?.firstname + ' ' + director?.element?.lastname }}</h1>
            <custom-image
                :image="director?.element?.memberImage?.[0]?.image"
                width="100%"
                :hover="false"
                @click="visibleRef = !visibleRef"
                v-ripple/>
            <v-expansion-panels>
                <v-expansion-panel
                    expand-icon="mdi-plus"
                    collapse-icon="mdi-minus"
                    :title="themeStore.translate('members-expansion-panel.title')"
                >
                    <v-expansion-panel-text v-html="director?.element?.vita">
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </article>
    </section>
</template>

<script setup>
import { defineProps } from "vue";
import customImage from "@/components/reuseables/customImage.vue";
import { useThemeStore } from "@/store/theme";

const themeStore = useThemeStore();

defineProps({
    data: Object,
});
</script>

